<template>
  <div class="hot-goods">
    <div class="title-box">
      <div class="title">
        <div class="dot"></div>
        <div>商品榜单</div>
      </div>
    </div>
    <div style="height: calc(100% - 110px)">
      <el-table :data="tableList" @sort-change="sortChange" height="100%" :header-cell-style="{ fontWeight: 'normal', height: '60px', color: '#666', background: '#F6F6F6', fontSize: '16px' }" :cell-style="{ fontSize: '16px', color: '#333', height: '60px' }">
        <el-table-column prop="sort" label="排名" width="60"></el-table-column>
        <el-table-column prop="goods_title" label="商品信息" width="600">
          <template slot-scope="scope">
            <div style="display: flex; align-items: center">
              <img :src="scope.row.goods_images" style="width: 40px; height: 40px; margin-right: 10px" />
              <div style="width: 500px" class="two-line-text">{{ scope.row.goods_title }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="amount_index" label="金额指数" sortable :sort-orders="['descending', 'ascending']"></el-table-column>
        <el-table-column prop="conversion_index" label="单量指数" sortable :sort-orders="['descending', 'ascending']"></el-table-column>
        <el-table-column prop="click_index" label="点击指数" sortable :sort-orders="['descending', 'ascending']"></el-table-column>
        <el-table-column prop="conversion_index" label="转化指数" sortable :sort-orders="['descending', 'ascending']"></el-table-column>
        <el-table-column align="right" label="操作" width="100">
          <template slot-scope="scope">
            <div class="link-box">
              <el-link type="success" :underline="false" @click="lookDetails(scope.row)">详情</el-link>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="width: 100%; margin-top: 20px; display: flex; justify-content: space-between; align-items: center">
      <div>总共{{ tableConfig.total }}条数据，当前第{{ tableConfig.page }}页</div>
      <el-pagination small background layout="prev, pager, next" v-model:current-page="tableConfig.page" :page-size="tableConfig.page_size" :total="tableConfig.total" @current-change="pageChange" />
    </div>
  </div>
</template>

<script>
import { hotGoodsList } from "@/utils/productService";

export default {
  name: "HotGoods",
  components: {},
  props: {},
  data() {
    return {
      tableList: [],
      tableConfig: {
        page: 1,
        pageSize: 9,
        total: 0,
      },
      params: {
        sort_field: void 0,
        sort_type: void 0,
      },
    };
  },
  computed: {},
  methods: {
    getTableList() {
      hotGoodsList({ ...{ paging: 1, page: this.tableConfig.page, limit: this.tableConfig.pageSize }, ...this.params }).then((res) => {
        this.tableList = res.data.list;
        this.tableConfig.total = res.data.total;
        this.goodsLimit = res.data.bing_num;
      });
    },
    pageChange(val) {
      this.tableConfig.page = val;
      this.getTableList();
    },
    sortChange(column, prop, order) {
      console.log({ column, prop, order });
      this.params.sort_field = column.prop;
      this.params.sort_type = column.order === "descending" ? "desc" : "dec";
      this.getTableList();
    },
    lookDetails(data) {
      let url = this.$router.resolve({ path: "/hotGoods/detail", query: { id: data.ps_goods_id } });
      console.log(url.href);
      window.open(url.href, "_blank");
    },
  },
  mounted() {
    this.getTableList();
  },
};
</script>

<style scoped lang="scss">
.hot-goods {
  box-sizing: border-box;
  height: 100%;
  padding: 10px 30px 30px 30px;
  .title-box {
    margin: 20px 0 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
    font-size: 16px;
    .title {
      display: flex;
      align-items: center;
      .dot {
        width: 4px;
        height: 20px;
        background: #2dc079;
        border-radius: 2px;
        margin-right: 10px;
      }
    }
  }
  .two-line-text {
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}
</style>
